.qg-modal h1 {
  color: #000;
}

.qg-modal a {
  color: #555;
  font-weight: bold;
}

.qg-modal a:hover {
  color: #aaa;
  font-weight: bold;
}
