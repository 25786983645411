@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
  font-family: 'Bangers';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./fonts/Bangers-Regular.woff2') format('woff2');
}

:root {
  --dark-font: #333;
  --primary: #ac6402;
  --white: #ffffff;
}

html {
  font-size: 20px;
}

body {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: normal;
  background-color: #131313;
  color: #fff;
}

p {
  line-height: 1.5rem;
}

a,
a:hover,
a:focus {
  color: #fff;
}

h1.bangers {
  font-family: 'Bangers';
}

hr {
  border-color: rgba(255, 255, 255, 0.15);
}

@media screen and (max-width: 700px) {
  html {
    font-size: 16px;
  }
}

/* uggly workaround for annoying hot reload iframe blocking issue in dev */
body > iframe:last-child {
  pointer-events: none;
}

html,
body,
#qg-body-wrapper,
.container-fluid,
.row,
#root,
.App {
  height: 100vh;
}

hr {
  margin: 1rem 0rem;
}

.text-heading {
  font-family: 'Bangers', sans-serif;
  margin-bottom: 0.5rem;
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: 0.1rem;
}

.text-distinct {
  font-weight: bold;
}

.text-meta {
  font-size: 0.75rem;
}

table {
  width: 100%;
}

table tr > td,
table tr > th {
  padding: 0.5rem;
  border-bottom: 1px solid #f9f9f91c;
  text-align: left;
}

table.qg-table td {
  background-color: rgba(31, 41, 55, 1);
  padding: 0.5rem;
  cursor: pointer;
}

table.qg-table tbody tr:hover td {
  background-color: rgba(40, 45, 60, 1);
}

.clickable {
  cursor: pointer;
}

.alert-danger {
  color: #ffffff;
  background-color: #c11d2c;
  border-color: #c11d2c;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
}

div.version {
  font-size: 0.9rem;
  margin-top: 1rem;
  color: rgba(255, 255, 255, 0.3);
}
