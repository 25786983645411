button.kep-login-facebook {
  width: 300px;
  height: 40px;
  font-size: 0.7rem !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 5rem;
  padding-left: 2.5rem !important;
}

.kep-login-facebook i {
  position: absolute;
  left: 0px;
  font-size: 36px;
}

.login-email {
  width: 300px;
  height: 40px;
}
