.admin-menu li {
  padding: 0.5rem;
  background-color: #444;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  cursor: pointer;
}

.admin-menu li:hover {
  background-color: #333;
}

.admin-menu li.active {
  background-color: var(--primary);
}

.admin-menu-mobile.show {
  right: 0;
  transition: 150ms;
}

.admin-menu-mobile.hide {
  right: -16rem;
}

.admin-menu-mobile li {
  padding: 1rem 2rem;
  background-color: #444;
  cursor: pointer;
}
