.qg-button.primary {
  color: white;
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

.qg-button.primary:hover {
  background-color: #854d02;
  border-color: #854d02;
}

.qg-button.secondary {
  color: #000;
  background-color: white;
  border: 1px solid #000;
}

.qg-button.danger {
  background-color: #94000e;
  border-color: #94000e;
  color: #fff;
}

.qg-button.success {
  color: #fff;
  background-color: #459256;
  border-color: #459256;
}

.qg-button.outline {
  color: #000;
  border: 1px solid #000;
}
